import React, { useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { LP_STATUS_TYPES, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBetween, RowFixed } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { useLocation } from 'react-router-dom'
import { JSBI } from '@cronosdex/sdk'
import { StyledToggle, ToggleElement } from '../../components/Toggle'
import { darken } from 'polished'
import { useLPBoostStakingInfo } from '../../state/boostStake/LPBoost'
import LPBoostCard from '../../components/Boosters/LPBoostCard'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link } from 'react-router-dom'
import { TransCard } from '../../components/Card'
import { Text } from 'rebass'
import { BarWave } from 'react-cssfx-loading'
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useLPRewardsV2Info } from '../../state/rewardsV2/lpRewardsv2'
import LPRewardsV2Card from '../../components/RewardsV2/LPRewardsV2Card'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const CustomTabs = styled(Tabs)`
  .MuiButtonBase-root {
    background: #6cbdb726;
    color: white;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    &.Mui-selected {
      color: white;
      font-weight: 600;
      background: #6cb5bd4f;
    }
  }
  .MuiTabs-indicator {
    background-color: #43ace3;
  }
`

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const OptionCustom = styled(FancyButton) <{ active?: boolean; warning?: boolean }>`
  height: 2.5rem;
  position: relative;
  padding: 0 0.75rem;
  flex: inherit;
  border: ${({ theme, active, warning }) => active && `1px solid ${warning ? theme.red1 : theme.primary1}`};
  :hover {
    border: ${({ theme, active, warning }) =>
    active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`
const CustomGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 24px;
  justify-items: center;
  max-width: 640px;
  width: 100%;
  margin-top: -4%;
`
const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5%;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  overflow: hidden auto;
  z-index: 1;
}
`
const StyleRowBetween = styled.div`
  background: #130f29a3;
  border: 1px solid #58498c;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
}
`

export default function Earn() {
  const queries = useLocation().search

  const statusFromQS = new URLSearchParams(queries).get('status')
  const statusInfo = statusFromQS ? parseInt(statusFromQS) : LP_STATUS_TYPES.LIVE

  let orgStakingInfos = useStakingInfo(undefined)
  let orgBoostStakingInfos = useLPBoostStakingInfo(undefined)
  let orgRewardV2Infos = useLPRewardsV2Info(undefined)
  
  const isLoading = orgStakingInfos?.length === 0 && orgBoostStakingInfos?.length === 0 && orgRewardV2Infos?.length === 0

  let stakingInfos = undefined;
  let boostStakingInfos = undefined;
  let rewardV2Infos = undefined;

  if (statusInfo !== undefined) {
    stakingInfos = orgStakingInfos?.filter(stakingInfo => 
      stakingInfo?.poolStatus === statusInfo ? true : false
    )
    boostStakingInfos = orgBoostStakingInfos?.filter(boostStakingInfo =>
      boostStakingInfo?.poolStatus === statusInfo ? true : false
    )
    rewardV2Infos = orgRewardV2Infos?.filter(rewardV2Info =>
      rewardV2Info?.status === statusInfo ? true : false
    )
  }

  const [filterText, setFilterText] = useState('')
  function filterByTokenSymbol(value: string) {
    setFilterText(value)
  }

  if (filterText) {
    const regex = new RegExp(filterText, 'i')
    stakingInfos = stakingInfos?.filter(stakingInfo =>
      regex.test(stakingInfo?.tokens[0]?.symbol!) || regex.test(stakingInfo?.tokens[1]?.symbol!) ? true: false
    )
    boostStakingInfos = boostStakingInfos?.filter(boostStakingInfo =>
      regex.test(boostStakingInfo?.tokens[0]?.symbol!) || regex.test(boostStakingInfo?.tokens[1]?.symbol!)? true: false
    )
    rewardV2Infos = rewardV2Infos?.filter(rewardV2Info =>
      regex.test(rewardV2Info?.tokens[0]?.symbol!) || regex.test(rewardV2Info?.tokens[1]?.symbol!) ? true: false
    )
  }

  const [myStakings, setMyStakings] = useState(false)
  function toggle() {
    setMyStakings(!myStakings)
  }

  if (myStakings) {
    stakingInfos = stakingInfos?.filter(stakingInfo =>
      stakingInfo?.stakedAmount?.raw && JSBI.toNumber(stakingInfo?.stakedAmount?.raw) ? true : false
    )

    boostStakingInfos = boostStakingInfos?.filter(boostStakingInfo =>
      boostStakingInfo?.stakedAmount?.raw && JSBI.toNumber(boostStakingInfo?.stakedAmount?.raw) ? true : false
    )

    rewardV2Infos = rewardV2Infos?.filter(rewardV2Info =>
      rewardV2Info?.stakedAmount?.raw && JSBI.toNumber(rewardV2Info?.stakedAmount?.raw) ? true : false
    )
  }

  const [value, setValue] = React.useState(statusInfo.toString())
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  
  const noResult = (stakingInfos && boostStakingInfos && rewardV2Infos) && stakingInfos?.length === 0 && boostStakingInfos?.length === 0 && rewardV2Infos?.length === 0

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600} fontSize={20}> Liquidity Mining</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens to earn rewards along with swap fees.
                </TYPE.white>
              </RowBetween>{' '}
            </AutoColumn>
          </CardSection>
        </DataCard>
      </TopSection>

      <CustomTabs value={value} onChange={handleChange} aria-label="nav tabs example">
        <Tab component={Link} value="0" label={<TYPE.white> Finished </TYPE.white>} to={`/rewards/pair?status=${LP_STATUS_TYPES.FINISHED}`} />
        <Tab component={Link} value="1" label={<TYPE.white> Paused </TYPE.white>} to={`/rewards/pair?status=${LP_STATUS_TYPES.HALTED}`} />
        <Tab component={Link} value="2" label={<TYPE.white> Live </TYPE.white>} to={`/rewards/pair?status=${LP_STATUS_TYPES.LIVE}`} />
      </CustomTabs>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <CustomDiv>
          <CustomGrid>
            <StyleRowBetween>
              <RowBetween style={{ marginBottom: '15px' }}>
                <RowFixed>
                  <TYPE.black fontWeight={600}>
                    My Deposits Only
                  </TYPE.black>
                </RowFixed>

                <StyledToggle isActive={true} onClick={toggle}>
                  <ToggleElement isActive={myStakings} isOnSwitch={true}>
                    On
                  </ToggleElement>
                  <ToggleElement isActive={!myStakings} isOnSwitch={true}>
                    Off
                  </ToggleElement>
                </StyledToggle>
              </RowBetween>

              <RowBetween>
                <TYPE.black fontWeight={600}>
                  Filter Tokens
                </TYPE.black>

                <OptionCustom style={{ width: '225px' }} tabIndex={-1}>
                  <Input placeholder={'CRX'} onChange={e => filterByTokenSymbol(e.target.value)} />
                </OptionCustom>
              </RowBetween>
            </StyleRowBetween>
            <PoolSection>
              
            { isLoading && (
                <TransCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center"></Text>
                    <BarWave style={{ margin: '20px auto' }} color="#5b5eb1"></BarWave>
                  </AutoColumn>
                </TransCard>
              )}

              { !isLoading && noResult && (
                <TransCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center">
                    <SearchOffIcon sx={{ fontSize: 48, color:"#5b5eb1" }}/>
                    </Text>
                  </AutoColumn>
                </TransCard>
              )}

              {rewardV2Infos &&
                rewardV2Infos?.map(rewardV2Info => {
                    return <LPRewardsV2Card key={rewardV2Info.stakingRewardAddress} rewardInfo={rewardV2Info} />
              })}


              {boostStakingInfos &&
                boostStakingInfos?.map(boostStakingInfo => {
                  return <LPBoostCard key={boostStakingInfo?.stakingRewardAddress} stakingInfo={boostStakingInfo} />
                })}

              {stakingInfos &&
                stakingInfos?.map(stakingInfo => {
                  return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
                })}
            </PoolSection>
          </CustomGrid>
        </CustomDiv>
      </AutoColumn>
    </PageWrapper>
  )
}
