import React, { useEffect } from 'react'
import styled from 'styled-components'
import muhbImage from '../../assets/images/mhubbg.jpg'
import { TYPE } from '../../theme'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link, useLocation } from 'react-router-dom'
import { AutoColumn } from '../../components/Column'
import HUB from './hub'
import ADVENTURE from './adventure'
import AUCTION from './auction'

const CustomTabs = styled(Tabs)`
  .MuiButtonBase-root {
    background: #6cbdb726;
    backdrop-filter: blur(30px);
    color: white;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    &.Mui-selected {
      color: white;
      font-weight: 600;
      background: #6cb5bd4f;
    }
  }
  .MuiTabs-indicator {
    background-color: #43ace3;
  }
`

const ImageDiv = styled.div`
  background-image: url(${muhbImage});
  background-size: cover;
  background-position: right top;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`
const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

// const PageWrapper = styled(AutoColumn)`
//   max-width: 640px;
//   width: 100%;
// `

export default function MHUB() {
  // const mhubContract = useMHubTokenContract(MHUB_TOKEN_CONTRACT_ADDRESSES)
  // const mhubInfo = useMHUBTokenInfo()
  // const [attempting, setAttempting] = useState(false)
  // const [hash, setHash] = useState<string | undefined>()
  const [selectedTab, setSelectedTab] = React.useState('')
  const [selectedContent, setSelectedContent] = React.useState(<HUB></HUB>)
  
  const queries = useLocation().search
  const selectedTabFromQS = new URLSearchParams(queries).get('page')

  useEffect(() => {
    if (selectedTabFromQS) {
      setSelectedTab(selectedTabFromQS)
      handleSelectedContent(selectedTabFromQS)
    } else {
      setSelectedTab('hub')
    }
  }, [selectedTabFromQS])

  // const addTransaction = useTransactionAdder()

  const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedTab(newValue)
    handleSelectedContent(newValue)
  }
  const handleSelectedContent = (newValue: any) => {

    switch (newValue) {
      case 'hub':
        setSelectedContent(<HUB></HUB>)
        break
      case 'adventure':
        setSelectedContent(<ADVENTURE></ADVENTURE>)
        break
      case 'auction':
        setSelectedContent(<AUCTION></AUCTION>)
        break
      default:
        setSelectedContent(<HUB></HUB>)
    }
  }

  return (
    <>
      <ImageDiv />

      <PageWrapper gap="lg" justify="center" style={{ maxWidth: '960px' }}>
      <TYPE.largeHeader style={{ fontFamily: 'GoodTimes', fontSize:'31px' }}>
                  METAVERSE HUB
        </TYPE.largeHeader>
        <CustomTabs value={selectedTab} onChange={handleChangeTab} aria-label="nav tabs example">
          <Tab component={Link} value="hub" label={<TYPE.white> Hub </TYPE.white>} to={`/mhub?page=hub`} />
          <Tab
            component={Link}
            value="adventure"
            label={<TYPE.white> Adventures</TYPE.white>}
            to={`/mhub?page=adventure`}
          />
          {/* <Tab component={Link} value="auction" label={<TYPE.white> Auction </TYPE.white>} to={`/mhub?page=auction`} /> */}
        </CustomTabs>
        {selectedContent}
      </PageWrapper>
    </>
  )
}
