// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://swap.crodex.app/tokens.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const DEFAULT_HARDCODED_TOKEN_LIST = {
  name: 'ERC20 Token List',
  timestamp: '2021-07-08T07:24:15.408Z',
  version: {
    major: 1,
    minor: 9,
    patch: 29
  },
  tags: {},
  logoURI: 'https://swap.crodex.app/cro.png',
  keywords: ['Cronos', 'default'],
  tokens: [
    {
      name: 'Crodex Token',
      address: '0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
      symbol: 'CRX',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crx.png'
    },
    {
      name: 'Wrapped CRO',
      address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
      symbol: 'wCRO',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/cro.png'
    },
    {
      name: 'Wrapped ETH',
      address: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
      symbol: 'wETH',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014'
    },
    {
      name: 'Wrapped BTC',
      address: '0x062E66477Faf219F25D27dCED647BF57C3107d52',
      symbol: 'wBTC',
      decimals: 8,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=014'
    },
    {
      name: 'USDC',
      address: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
      symbol: 'USDC',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=014'
    },
    {
      name: 'USDT',
      address: '0x66e428c3f67a68878562e79A0234c1F83c208770',
      symbol: 'USDT',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.png?v=014'
    },
    {
      name: 'ATOM',
      address: '0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93',
      symbol: 'ATOM',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/atom.svg'
    },
    {
      name: 'CroKing',
      address: '0x7C8b5501A40D382e8A11889834c80b2D7Fa1Fc4F',
      symbol: 'CRK',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/croking.png'
    },
    {
      name: 'IcyCRO',
      address: '0x8F857Af6Ea31447Bb502fE0E3F4e4340CDFCfc6C',
      symbol: 'ICY',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/ICY.png'
    },
    {
      name: 'CroArmy',
      address: '0xb20115b8b176d72f077c4f93e95492870240a0b2',
      symbol: 'ARMY',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/ARMY.png'
    },
    {
      name: 'CUJO',
      address: '0xfe727fAf84a8b0aC925D2D469D5b9ae5D995d22D',
      symbol: 'CUJO',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/cujo.png'
    },
    {
      name: 'ROLLIUM',
      address: '0x19258a1df9E929D02b34621CF52797998aE1Aa27',
      symbol: 'RLM',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://marbleversecdn.ams3.digitaloceanspaces.com/MarbleVerse/RLM200x200.png'
    },
    {
      name: 'CronosVerse',
      address: '0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1',
      symbol: 'VRSE',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/CRONOSVERSE.png'
    },
    {
      name: 'Crolon Mars',
      address: '0xaAd00d36Dbc8343C3505Ba51418a43D3622D2964',
      symbol: 'CLMRS',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/CLMRS.jpg'
    },
    {
      name: 'GDRTOKEN',
      address: '0xeC0d0f2D7dDF5e6F1Ed18711fE5DD5C790E1C4d6',
      symbol: 'GDRT',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/GDRT.jpg'
    },
    {
      name: 'Metaverse Hub',
      address: '0x63E65C6d7c43F1BA6188943Cc89ba4b002a2AD0D',
      symbol: 'MHUB',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/MHUB.png'
    },
    {
      name: 'xCRX',
      address: '0x9315054f01bF8c13EE67C8498Af09A1933cbF24c',
      symbol: 'xCRX',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/XCRX.png'
    },
    {
      name: 'THUNDER',
      address: '0xE71D72436F290Cd98CC29c9EF0E15C88Ce57B359',
      symbol: 'THNDR',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://imagedelivery.net/4JqAggdip6pa4pVpaZLoDA/token-thndr/scale'
    },
    {
      name: 'Sycotic Society',
      address: '0xE7F3c7C6611357313B5C2734341fe9cBad1D9f59',
      symbol: 'Sycotic',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://www.sycoticsociety.com/images/sycoticTokenSwap.png'
    },
    {
      name: 'Steak Token',
      address: '0x4137A9F6eb939a8d7d620b239B562596E48d6F41',
      symbol: 'STEAK',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/STEAK.png'
    },
    {
      name: 'SHINU',
      address: '0x66354fA2b47Bfc05b84CA590c775803D9356f8a4',
      symbol: 'SHINU',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/SHINU.jpg'
    },
    {
      name: 'TOE',
      address: '0x01e584B20EcB9855D34306D88DA3E1A442de523B',
      symbol: 'TOE',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://img1.wsimg.com/isteam/ip/ebc5eca0-78b9-4101-a78f-265685f00917/Toe%20Image.png/:/rs=w:200,h:200,cg:true,m/cr=w:200,h:200/qt=q:95'
    },
    {
      name: 'GRNSTXv2',
      address: '0x16a19Ae876272fb9Cc8dF78a116C63Cc1562270b',
      symbol: 'GRNSTXv2',
      decimals: 9,
      chainId: 25,
      logoURI:
        'https://corgistudio.fra1.digitaloceanspaces.com/GreenStix%20EcoFriendly%20_64a5e429-5f94-40e6-996e-68b4138a1f1c/cover.png?t=1671878464216'
    },
    {
      name: 'Corgi Studio',
      address: '0x70F56e8f8af82a943d6894a7770ff23946b35081',
      symbol: 'CRS',
      decimals: 9,
      chainId: 25,
      logoURI:
        'https://media.discordapp.net/attachments/938803313053417472/1111712305152004186/telegram-cloud-photo-size-1-5154646691998182498-y.jpg?width=936&height=936'
    },
    {
      name: 'LADY SHINU',
      address: '0x80ac1D279d498e726619a513BaAb8e6A0FEcF7EF',
      symbol: 'LADY',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/LADY.png'
    },
    {
      name: 'GOAT',
      address: '0x150ab8D4BDf928d4578DC0C06D76832867f19E56',
      symbol: 'GOAT',
      decimals: 8,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/GOAT.png'
    },
    {
      name: 'BOO',
      address: '0x3484D2216174aD804808DE2D25d807Bf21535b46',
      symbol: 'BOO',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cdn.discordapp.com/attachments/1031654545924837397/1085677271274225756/BOO_Token_Logo.png'
    },
    {
      name: 'REYE',
      address: '0xC12c4892C29F815157F399D3662FE94f0B23cd34',
      symbol: 'REYE',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cdn.discordapp.com/attachments/1024302317446635550/1085680771492216943/REYE.png'
    },
    {
      name: 'CROvid-19',
      address: '0x831BcE594d2b24303E94Db71E662554877bDB33E',
      symbol: 'CROvid-19',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/1041090625136754698/1085936111450341467/qSocc1I04Aai8HI3gXgG--1--v7obt.jpg'
    },
    {
      name: 'JOEY',
      address: '0x22F1cDC24D58b33bB02248CC3AeFBE895d56056c',
      symbol: 'JOEY',
      decimals: 9,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/1077760917904834630/1095205606400471171/A87C7779-23A3-4E6E-84E7-5C31D4DE79D1.jpg'
    },
    {
      name: 'ROAST',
      address: '0x513b35dFEb34a3cDb673d9b9421532F545d86bb1',
      symbol: 'ROAST',
      decimals: 9,
      chainId: 25,
      logoURI: 'https://cdn.discordapp.com/attachments/1024302317446635550/1100173868070600704/roast_option_3.png'
    },
    {
      name: 'LOTUS',
      address: '0x7c401da133eDD005570BC98732c8C0246DD14a4a',
      symbol: 'LOTUS',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://media.discordapp.net/attachments/938803313053417472/1105611069235400755/telegram-cloud-photo-size-1-5102913823802764163-x.jpg'
    },
    {
      name: 'FEED',
      address: '0xfed7E7612253386ae899ED7EFfCE2a520D9c689e',
      symbol: 'FEED',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://media.discordapp.net/attachments/938803313053417472/1111976316435189770/corn_coin.png?width=936&height=936'
    },
    {
      name: 'CROjak',
      address: '0x37FB280b118A217e85f58Cbbf096ab249aB41459',
      symbol: 'CROjak',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/5hJqdGy/IMG-3372.jpg'
    },
    {
      name: 'CSF',
      address: '0xF86243d9ef98Ae8079011a40ddf35F474418C249',
      symbol: 'CSF',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/938803313053417472/1114671462582861905/telegram-cloud-photo-size-5-6329990666079285973-x.jpg'
    },
    {
      name: 'WISH',
      address: '0xBE8B87a81d9C1d954D5Ab35B7df476b6587e8d92',
      symbol: 'WISH',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://i.imghippo.com/files/rjWgq1716494845.jpg'
    },
    {
      name: 'DAOLAB',
      address: '0x3E52e3c3F805623Bec8d30c2A6e8aa508751443c',
      symbol: 'DAOLAB',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cdn.discordapp.com/attachments/938803313053417472/1115746976777121902/Logo_1000x1000.png'
    },
    {
      name: 'FREE',
      address: '0x4E2E7700C8f8A414b4AF8828f54330a304B6625d',
      symbol: 'FREE',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://www.sycoticsociety.com/images/freeCoinTokenSwap.png'
    },
    {
      name: 'bCRO',
      address: '0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F',
      symbol: 'bCRO',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/3Rp46ZP/image.png'
    },
    {
      name: 'BrokeASF',
      address: '0x3E68eA1588E32e51D98dBda6Ea05128513ECC713',
      symbol: 'BrokeASF',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://www.sycoticsociety.com/images/brokeAsfTokenSwap.png'
    },
    {
      name: 'GR$',
      address: '0x283198e50ee5958aFdA80577346AA73F98c971d7',
      symbol: 'GR$',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/1041090625136754698/1156913407170203778/Get_rekt_2.png?ex=6516b312&is=65156192&hm=e75699a6f89191940fe6f118ceec9f726367a63113946673b8967b21b5e029a7&'
    },
    {
      name: 'LEAF',
      address: '0x469B253Aa99D7bbf6c8053cDd3c075Ca04f14236',
      symbol: 'LEAF',
      decimals: 9,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/1053032264130379808/1158467518306975855/IMG_4423.jpg?ex=651c5a73&is=651b08f3&hm=b60868fcaa0f446d43ce72df476a674789031eae3b288e9ce9208aa5f39b2b1c&'
    },
    {
      name: 'BurnCro',
      address: '0x06725e8c3c54A1D3A7109E43a2d928a7De462eaa',
      symbol: 'BC',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cronoscan.com/token/images/burncro_32.png?=v73'
    },
    {
      name: 'Pizza',
      address: '0xbf2bbd631d15f699d2092D85E873308da49bE0d4',
      symbol: 'PZZA',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://cdn.discordapp.com/attachments/1080975153611149412/1197268091348988015/pizza_logo_2.png?ex=65baa5cd&is=65a830cd&hm=76b0b918c8004907c85cae1aa850c3afc112f969c746601f82f2779e8e6a4269&'
    },
    {
      name: 'IcyCRO VIP',
      address: '0x6422Cb6b0A71E1813904ea9B92aB2102EcbaD580',
      symbol: 'VIPICY',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://i.imghippo.com/files/7bStm1716142560.png'
    },
    {
      name: 'Hell Fire Cro',
      address: '0xDdcC8a4D729626B4aE8Bf9f14f14817245Cb2d48',
      symbol: 'HFC',
      decimals: 9,
      chainId: 25,
      logoURI:
        'https://dd.dexscreener.com/ds-data/tokens/cronos/0xddcc8a4d729626b4ae8bf9f14f14817245cb2d48.png?size=lg&key=415c0d'
    },
    {
      name: 'MARS',
      address: '0x1918c40919251a6106FD3c012765f0973B657B1E',
      symbol: 'MARS',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/rcL5XYG/Logo.png'
    },
    {
      name: 'GAT',
      address: '0x8Ab1C78364eB29aD4aE509fF6b03E3E7bD4f8d10',
      symbol: 'GAT',
      decimals: 18,
      chainId: 25,
      logoURI:
        'https://dd.dexscreener.com/ds-data/tokens/cronos/0x8ab1c78364eb29ad4ae509ff6b03e3e7bd4f8d10.png?size=lg&key=5fa88d'
    },
    {
      name: 'CROB',
      address: '0x63eD0a82cAC237667C89Cd6AC5BFa2317186FdAa',
      symbol: 'CROB',
      decimals: 18,
      chainId: 25,
      logoURI:'https://dd.dexscreener.com/ds-data/tokens/cronos/0x63ed0a82cac237667c89cd6ac5bfa2317186fdaa.png?size=lg&key=915973'
    },
    {
      name: 'Mistery',
      address: '0x3b41B27E74Dd366CE27cB389dc7877D4e1516d4d',
      symbol: 'MERY',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/5krGFcn/mery.jpg'
    },
    {
      name: 'ORC',
      address: '0xB26C3A4fBaf42104EeDad8274aE74b88F27c06dE',
      symbol: 'ORC',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://pbs.twimg.com/profile_images/1773452307578994688/lO6TWAXK_400x400.png'
    },
    {
      name: 'BTCCRONOS',
      address: '0xEfE15a7232b9F0aA890FF97Aaf53afe87D00F8BE',
      symbol: 'BTCCRONOS',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/NT07Dt6/IMG-1652.jpg'
    },
    {
      name: 'xARMY',
      address: '0xBc832AB622985E29BAB01C4BC2A4BA5AB84AB6b0',
      symbol: 'xARMY',
      decimals: 9,
      chainId: 25,
      logoURI: 'https://images.squarespace-cdn.com/content/v1/62321dbcbc71146c160c73bf/66642d9f-9cbc-449b-be34-2f710f2dddb0/Xarmy.jpg?format=1500w'
    },
    {
      name: 'CPB',
      address: '0x7c4406c76f067353bd4ea7f8e8a47a6803bc508a',
      symbol: 'CPB',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://dd.dexscreener.com/ds-data/tokens/cronos/0x7c4406c76f067353bd4ea7f8e8a47a6803bc508a.png?size=lg&key=e611d3'
    },
    {
      name: 'CPBR',
      address: '0xe0fd6a8e1073dc7248920147da84fcf74c7d04d7',
      symbol: 'CPBR',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.imghippo.com/files/9Ke1r1716237446.png'
    },
    {
      name: 'CSAI',
      address: '0x6519bd0745f21f34c22bbb16aba24601e9b1f366',
      symbol: 'CSAI',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://i.ibb.co/qyz9SbV/image.png'
    }
  ]
}
