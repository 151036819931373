import { ChainId, JSBI, Percent, Token, WETH } from '@cronosdex/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { defiWalletConnect, injected, walletconnect } from '../connectors'

export const ROUTER_ADDRESS = '0xeC0A7a0C2439E8Cb67b992b12ecd020Ea943c7Be'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const GRAPH_URI = "https://infoapi.crodex.app/subgraphs/name/crograph2/crodex2"

export const GOD_WALLETS = [
  "0xCae4254841aCeaf214375A174f70936eCa5Aced7",
  "0xdB6D87581A04f72F062269Afc00E8e41633AE204",
  "0x52FE2bA803e66A6D2A3ff462DcCc4c6ffc43cfC7",
  "0x28F6f4AdeA15D2C216bFE283171b671E76b9B718",
  "0xda07E766dFDff60657eC88dDfd3318B8eB5e2377",
  "0xAB8a210ae0d06c2eb4140BDBa5228bf79A8d461f"
]

export const BOOSTED_POOLS_DEPLOYER_WALLET = "0xAB8a210ae0d06c2eb4140BDBa5228bf79A8d461f"
export const BOOSTED_POOLS_BENEFICIARY_WALLET = "0xda07E766dFDff60657eC88dDfd3318B8eB5e2377"

export const NFT_STAKE_DEPLOYER_WALLET = "0x28F6f4AdeA15D2C216bFE283171b671E76b9B718"
export const NFT_STAKE_BENEFICIARY_WALLET = "0xda07E766dFDff60657eC88dDfd3318B8eB5e2377"

export const CURRENT_REWARDS_ROUND = 6
export const NFT_CURRENT_REWARDS_ROUND = 5

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const TKYY = new Token(
  ChainId.CRONOSMAINNET,
  '0x6B937BcbD7F2BB148957a74B7F73544727bF1d38',
  18,
  'TKYY',
  'TKYY'
)


export const NATIVE_CRO = new Token(
  ChainId.CRONOSMAINNET,
  '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  18,
  'CRO',
  'CRO'
)


export const COMP = new Token(
  ChainId.CRONOSMAINNET,
  '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  18,
  'COMP',
  'Compound'
)
export const MKR = new Token(ChainId.CRONOSMAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(
  ChainId.CRONOSMAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)

//relay bridge
export const WIND = new Token(ChainId.CRONOSMAINNET, '0x48713151E5AFB7b4CC45f3653c1c59CF81E88D4B', 18, 'WIND', 'WIND')
export const MIM = new Token(ChainId.CRONOSMAINNET, '0x2BC472832Eb20C65F82d6A869db845aB0C0099ba', 18, 'MIM', 'MIM')
export const KAFE = new Token(ChainId.CRONOSMAINNET, '0xD62A9d7BE4404675ab0e5CBD5DfCE4aa6A15566E', 18, 'KAFE', 'KAFE')
export const MAI = new Token(
  ChainId.CRONOSMAINNET,
  '0x1c965D8E53fb1a448789e2B0FA5abc3EB2c36993',
  18,
  'MAI',
  'MAI'
)

export const MAI_NATIVE = new Token(
  ChainId.CRONOSMAINNET,
  '0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb',
  18,
  'MAI',
  'MAI'
)

export const RELAY = new Token(
  ChainId.CRONOSMAINNET,
  '0x9C29650a1B273A031A35F3121914aae882B144A4',
  18,
  'RELAY',
  'RELAY'
)
export const AVAX = new Token(ChainId.CRONOSMAINNET, '0xe7e12F436ae05cE6623eFB68B28ca4b83A953600', 18, 'AVAX', 'AVAX')
export const MATIC = new Token(
  ChainId.CRONOSMAINNET,
  '0xad79AC3c5a5c15C6B9194F5568e451b3fc3C2B40',
  18,
  'MATIC',
  'MATIC'
)
export const FREE = new Token(
  ChainId.CRONOSMAINNET,
  '0x9209d64F2930DaAe334e0fc2939709E593A88041',
  18,
  'FreeRiver',
  'FreeRiver'
)
export const POLYDOGE = new Token(
  ChainId.CRONOSMAINNET,
  '0x9b8077C6590b560f1A9D60512648277D29b35A3B',
  18,
  'PolyDoge',
  'PolyDoge'
)
export const CRYSTL = new Token(
  ChainId.CRONOSMAINNET,
  '0xCbDE0E17d14F49e10a10302a32d17AE88a7Ecb8B',
  18,
  'CRYSTL',
  'CRYSTL'
)
//any bridge
export const FTM = new Token(ChainId.CRONOSMAINNET, '0xB44a9B6905aF7c801311e8F4E76932ee959c663C', 18, 'FTM', 'FTM')
export const BNB = new Token(ChainId.CRONOSMAINNET, '0xfa9343c3897324496a05fc75abed6bac29f8a40f', 18, 'BNB', 'BNB')
export const BUSD = new Token(ChainId.CRONOSMAINNET, '0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8', 18, 'BUSD', 'BUSD')

export const WRAPPEDETHER = new Token(
  ChainId.CRONOSMAINNET,
  '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
  18,
  'wETH',
  'Wrapped Ether'
)
export const DAI = new Token(
  ChainId.CRONOSMAINNET,
  '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC = new Token(ChainId.CRONOSMAINNET, '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6, 'USDC', 'USD//C')
export const USDT = new Token(
  ChainId.CRONOSMAINNET,
  '0x66e428c3f67a68878562e79A0234c1F83c208770',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(
  ChainId.CRONOSMAINNET,
  '0x062E66477Faf219F25D27dCED647BF57C3107d52',
  8,
  'wBTC',
  'Wrapped BTC'
)
export const WCRO = new Token(
  ChainId.CRONOSMAINNET,
  '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  18,
  'wCRO',
  'Wrapped CRO'
)
export const TST = new Token(
  ChainId.CRONOSMAINNET,
  '0x32218d3673032B87B1776Db230a976C327E870cA',
  18,
  'TeST',
  'TEST TOKEN'
)
export const TST2 = new Token(
  ChainId.CRONOSMAINNET,
  '0xF00d97ffe01d30de56bb540009F26F8C9945efd3',
  18,
  'TEST2',
  'TEST2 TOKEN'
)

export const TCN = new Token(
  ChainId.CRONOSMAINNET,
  '0x64125B3f54EF500D4A91820c7e727C4D47174698',
  18,
  'TCN',
  'TCN'
)

export const ELK = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
  18,
  'ELK',
  'ELK'
)

export const AUTO = new Token(
  ChainId.CRONOSMAINNET,
  '0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa',
  18,
  'AUTO',
  'AUTO'
)

export const CROMOON = new Token(
  ChainId.CRONOSMAINNET,
  '0x7d30c36f845d1dee79f852abf3a8a402fadf3b53',
  9,
  'CroMoon',
  'CroMoon'
)

export const CRONIC = new Token(
  ChainId.CRONOSMAINNET,
  '0x4A194B6A25a0d29B860D7D71e015EC15a1E26c99',
  9,
  'CRONIC',
  'CRONIC'
)

export const TENDIES = new Token(
  ChainId.CRONOSMAINNET,
  '0x2fDcB16a977921FaDac3A9E03D18aDa77B4aA3F5',
  9,
  'TENDIES',
  'TENDIES'
)

export const LOOTNETWORK = new Token(
  ChainId.CRONOSMAINNET,
  '0xEd34211cDD2cf76C3cceE162761A72d7b6601E2B',
  18,
  'LOOT',
  'LOOT'
)

export const TREASURE = new Token(
  ChainId.CRONOSMAINNET,
  '0x2924C3071E131580e597E0a8Ff5f0621ff0C2cc9',
  18,
  'TREASURE',
  'TREASURE'
)

export const METAPX = new Token(
  ChainId.CRONOSMAINNET,
  '0x03627840190152771e86e5E702175B00219F6471',
  18,
  'METAPX',
  'METAPX'
)

export const DAOLAB = new Token(
  ChainId.CRONOSMAINNET,
  '0x3E52e3c3F805623Bec8d30c2A6e8aa508751443c',
  18,
  'DAOLAB',
  'DAOLAB'
)

export const BURNCRO = new Token(
  ChainId.CRONOSMAINNET,
  '0x06725e8c3c54A1D3A7109E43a2d928a7De462eaa',
  18,
  'BurnCro',
  'BurnCro'
)

export const ATOM = new Token(
  ChainId.CRONOSMAINNET,
  '0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93',
  6,
  'ATOM',
  'ATOM'
)

export const CROHC = new Token(
  ChainId.CRONOSMAINNET,
  '0xf45883d7b67438ff68261978967e838ed50e0c00',
  6,
  'CROHC',
  'CROHC'
)

export const BOO = new Token(
  ChainId.CRONOSMAINNET,
  '0x3484D2216174aD804808DE2D25d807Bf21535b46',
  18,
  'BOO',
  'BOO'
)

export const CROKING = new Token(
  ChainId.CRONOSMAINNET,
  '0x065DE42E28E42d90c2052a1B49e7f83806Af0e1F',
  9,
  'CRK',
  'CROKING'
)

export const LUNA = new Token(
  ChainId.CRONOSMAINNET,
  '0x9278c8693e7328bef49804bacbfb63253565dffd',
  6,
  'LUNA',
  'LUNA'
)

export const ADA = new Token(
  ChainId.CRONOSMAINNET,
  '0x0e517979C2c1c1522ddB0c73905e0D39b3F990c0',
  6,
  'ADA',
  'ADA'
)

export const HIBIKI = new Token(
  ChainId.CRONOSMAINNET,
  '0x6B66fCB66Dba37F99876a15303b759c73fc54ed0',
  18,
  'cHIBIKI',
  'cHIBIKI'
)

export const WISH = new Token(
  ChainId.CRONOSMAINNET,
  '0xBE8B87a81d9C1d954D5Ab35B7df476b6587e8d92',
  18,
  'WISH',
  'WISH'
)

export const PEPE = new Token(
  ChainId.CRONOSMAINNET,
  '0x9d5991f523f3aF7c14e91Ca6063C8E08F3c97fbB',
  18,
  'PEPE',
  'PEPE'
)

export const DWL = new Token(
  ChainId.CRONOSMAINNET,
  '0xef7152b3A4E21eCE60839C20230F45e33F1913B5',
  18,
  'DWL',
  'DWL'
)

export const CPB = new Token(
  ChainId.CRONOSMAINNET,
  '0x7c4406c76f067353bd4ea7f8e8a47a6803bc508a',
  18,
  'CPB',
  'CPB'
)

export const CPBR = new Token(
  ChainId.CRONOSMAINNET,
  '0xe0fd6a8e1073dc7248920147da84fcf74c7d04d7',
  18,
  'CPBR',
  'CPBR'
)


export const CRX = new Token(
  ChainId.CRONOSMAINNET,
  '0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
  18,
  'CRX',
  'CRODEX'
)

export const XCRX = new Token(
  ChainId.CRONOSMAINNET,
  '0x9315054f01bF8c13EE67C8498Af09A1933cbF24c',
  18,
  'xCRX',
  'xCRX'
)

export const RCRX = new Token(
  ChainId.CRONOSMAINNET,
  '0x8b41783Edd3F4A5D30AC4f8c5267BC353015aF4c',
  18,
  'rCRX',
  'rCRX'
)

export const YYMKP = new Token(
  ChainId.CRONOSMAINNET,
  '0xdca11779C44014B21C233DBE5FeA165178D07401',
  18,
  'YYMKP',
  'YYMKP'
)


export const ROLLIUM = new Token(
  ChainId.CRONOSMAINNET,
  '0x19258a1df9E929D02b34621CF52797998aE1Aa27',
  18,
  'RLM',
  'ROLLIUM'
)

export const CRONOSVERSE = new Token(
  ChainId.CRONOSMAINNET,
  '0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1',
  18,
  'VRSE',
  'CronosVerse'
)

export const METAVERSEHUB = new Token(
  ChainId.CRONOSMAINNET,
  '0x63E65C6d7c43F1BA6188943Cc89ba4b002a2AD0D',
  18,
  'MHUB',
  'Metaverse Hub'
)

export const GDRT = new Token(
  ChainId.CRONOSMAINNET,
  '0xeC0d0f2D7dDF5e6F1Ed18711fE5DD5C790E1C4d6',
  18,
  'GDRT',
  'GDRT'
)

export const SCOOTER = new Token(
  ChainId.CRONOSMAINNET,
  '0x7b42BbcEC77663147BC15B038830532A61FDA59b',
  18,
  'SCOOTER',
  'SCOOTER'
)

export const WTS = new Token(
  ChainId.CRONOSMAINNET,
  '0xB5D5758EC5811ef5E63E294388cF7B657F4be8DE',
  18,
  'WTS',
  'WTS'
)

export const CLMRS = new Token(
  ChainId.CRONOSMAINNET,
  '0xaAd00d36Dbc8343C3505Ba51418a43D3622D2964',
  18,
  'CLMRS',
  'CLMRS'
)

export const C_TENDIES = new Token(
  ChainId.CRONOSMAINNET,
  '0xde9d409F864Fa2aB16255DfEc166dFFc4fB13f40',
  9,
  'TENDIES',
  'TENDIES'
)

export const GRNSTX_V2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x16a19Ae876272fb9Cc8dF78a116C63Cc1562270b',
  9,
  'GRNSTX v2',
  'GRNSTX v2'
)


export const FAKE_ROLLIUM = new Token(
  ChainId.CRONOSMAINNET,
  '0x757676f0a0843eD9Dfb59c5fAa282226e9C524d4',
  18,
  'FAKE ROLLIUM',
  'FAKE ROLLIUM'
)

export const THNDR = new Token(
  ChainId.CRONOSMAINNET,
  '0xE71D72436F290Cd98CC29c9EF0E15C88Ce57B359',
  18,
  'THNDR',
  'THNDR'
)

export const GOAT = new Token(
  ChainId.CRONOSMAINNET,
  '0x150ab8D4BDf928d4578DC0C06D76832867f19E56',
  8,
  'GOAT',
  'GOAT'
)

export const LADY = new Token(
  ChainId.CRONOSMAINNET,
  '0x80ac1D279d498e726619a513BaAb8e6A0FEcF7EF',
  18,
  'LADY',
  'LADY'
)

export const ALIENCRO = new Token(
  ChainId.CRONOSMAINNET,
  '0x8a207b11177d9029eBB04BA86b4a9D2cF74835b7',
  9,
  'ALIEN',
  'ALIENCRO'
)

export const TATAS_TWISTED_TIME = new Token(
  ChainId.CRONOSMAINNET,
  '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6',
  18,
  'TTT',
  'TTT'
)

export const BOBA = new Token(
  ChainId.CRONOSMAINNET,
  '0xb78f4e392bB5b7A8E2E548A5C0dce3d64e19aeFE',
  18,
  'BOBA',
  'BOBA'
)

export const BOBZ = new Token(
  ChainId.CRONOSMAINNET,
  '0xBCfE5afF53fb269969725c12e5b9C3ab18B3B66c',
  18,
  'BOBZ',
  'BOBZ'
)

export const CROBANK = new Token(
  ChainId.CRONOSMAINNET,
  '0x55210C2a69b4c52a9d9289A257D54d35C4a2d2eC',
  9,
  'CROBANK',
  'CROBANK'
)


export const ICY = new Token(
  ChainId.CRONOSMAINNET,
  '0x8F857Af6Ea31447Bb502fE0E3F4e4340CDFCfc6C',
  18,
  'ICY',
  'ICY'
)

export const DARK = new Token(
  ChainId.CRONOSMAINNET,
  '0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5',
  18,
  'DARK',
  'DarkCrypto'
)

export const COUGAR = new Token(
  ChainId.CRONOSMAINNET,
  '0x4e57e27e4166275Eb7f4966b42A201d76e481B03',
  18,
  'CGS',
  'Cougar'
)

export const BABY_CROGE = new Token(
  ChainId.CRONOSMAINNET,
  '0xeeEB3389187E543B76385ddCCd5af9B972Ba2cC0',
  9,
  'BABYCROGE',
  'BABYCROGE'
)

export const SCRGE = new Token(
  ChainId.CRONOSMAINNET,
  '0xcbef7c136118886487622303ca5395a32ebb3d72',
  9,
  'SCRGE',
  'SquidCrowge'
)

export const BPHENIX = new Token(
  ChainId.CRONOSMAINNET,
  '0xd316DA9A1021788e75692fFaC7992e86FF95fB2b',
  9,
  'BPHENIX',
  'Baby Phenix'
)

export const CRONOS_BOSS = new Token(
  ChainId.CRONOSMAINNET,
  '0x2cfdE1967a375D1FD4Dd3E1f65236C4f6eF89475',
  9,
  'CBOSS',
  'Cronos Boss'
)

export const CORGI = new Token(
  ChainId.CRONOSMAINNET,
  '0xCC6e924721F75af3A8F8282216569276f3F2a104',
  9,
  'CORGI',
  'CORGI'
)

export const CROARMY = new Token(
  ChainId.CRONOSMAINNET,
  '0xb20115b8b176d72f077c4f93e95492870240a0b2',
  18,
  'ARMY',
  'CroArmy'
)

export const XARMY = new Token(
  ChainId.CRONOSMAINNET,
  '0xBc832AB622985E29BAB01C4BC2A4BA5AB84AB6b0',
  9,
  'xARMY',
  'xARMY'
)

export const APECOIN = new Token(
  ChainId.CRONOSMAINNET,
  '0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7',
  18,
  'APE',
  'ApeCoin'
)

export const CUJO = new Token(
  ChainId.CRONOSMAINNET,
  '0xfe727fAf84a8b0aC925D2D469D5b9ae5D995d22D',
  18,
  'CUJO',
  'CUJO'
)

export const FEED = new Token(
  ChainId.CRONOSMAINNET,
  '0xfed7E7612253386ae899ED7EFfCE2a520D9c689e',
  18,
  'FEED',
  'FEED'
)

export const BUSHI = new Token(
  ChainId.CRONOSMAINNET,
  '0xe7e479FCC3A722225fdBfA8Faea556E8a5eD904a',
  18,
  'BUSHI',
  'BUSHI'
)

export const BCRO = new Token(
  ChainId.CRONOSMAINNET,
  '0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F',
  18,
  'bCRO',
  'bCRO'
)

export const VIPICY = new Token(
  ChainId.CRONOSMAINNET,
  '0x6422Cb6b0A71E1813904ea9B92aB2102EcbaD580',
  18,
  'VIPICY',
  'VIPICY'
)

export const ROAST = new Token(
  ChainId.CRONOSMAINNET,
  '0x513b35dFEb34a3cDb673d9b9421532F545d86bb1',
  9,
  'ROAST',
  'ROAST'
)


export const CRONOSCHAD = new Token(
  ChainId.CRONOSMAINNET,
  '0xf8c57879939D1832efed51a85E20400c21aA50B8',
  9,
  'CroCHAD',
  'CroCHAD'
)

export const SYCOTIC = new Token(
  ChainId.CRONOSMAINNET,
  '0xE7F3c7C6611357313B5C2734341fe9cBad1D9f59',
  9,
  'Sycotic',
  'Sycotic Society'
)

export const REYE = new Token(
  ChainId.CRONOSMAINNET,
  '0xC12c4892C29F815157F399D3662FE94f0B23cd34',
  18,
  'REYE',
  'REYE'
)

export const CROB = new Token(
  ChainId.CRONOSMAINNET,
  '0x63eD0a82cAC237667C89Cd6AC5BFa2317186FdAa',
  18,
  'CROB',
  'CROB'
)

export const ORC_TEST1 = new Token(
  ChainId.CRONOSMAINNET,
  '0x6326Ce71d33c7aD83Eb0E2b7aBCb90fBa491F327',
  18,
  'orctest_1',
  'orctest_1'
)

export const ORC = new Token(
  ChainId.CRONOSMAINNET,
  '0xB26C3A4fBaf42104EeDad8274aE74b88F27c06dE',
  18,
  'ORC',
  'ORC'
)

export const DNOT = new Token(
  ChainId.CRONOSMAINNET,
  '0xa566A3ef60EE9C55e9Db6c927aAc9c8AcAAcaF1f',
  18,
  'DNOT',
  'DNOT'
)

export const CSAI = new Token(
  ChainId.CRONOSMAINNET,
  '0x6519BD0745f21F34C22bBb16aBA24601e9B1f366',
  18,
  'CSAI',
  'CSAI'
)

export const PPTZ = new Token(
  ChainId.CRONOSMAINNET,
  '0xe1f64F1617F238D7dEb1e0951074BF253De07E48',
  18,
  'PPTZ',
  'PPTZ'
)


export const GREENSTIX_V2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x16a19Ae876272fb9Cc8dF78a116C63Cc1562270b',
  9,
  'GRNSTXV2',
  'GRNSTXV2'
)

export const LIGHTWAVE = new Token(
  ChainId.CRONOSMAINNET,
  '0xc8324619A829efc3af5fF3206F136Ba8d26f071E',
  18,
  'LWV',
  'LWV'
)

export const CRONK = new Token(
  ChainId.CRONOSMAINNET,
  '0x35bEC47922B187d819C3d71CE872086F22F60477',
  9,
  'CRONK',
  'CRONK'
)

export const SHINU = new Token(
  ChainId.CRONOSMAINNET,
  '0x66354fA2b47Bfc05b84CA590c775803D9356f8a4',
  18,
  'SHINU',
  'SHINU'
)

export const XTUK = new Token(
  ChainId.CRONOSMAINNET,
  '0x32960c22037a870da5299c2c84e12ed15b5fa792',
  18,
  'XTUK',
  'XTUK'
)

export const STEAK = new Token(
  ChainId.CRONOSMAINNET,
  '0x4137A9F6eb939a8d7d620b239B562596E48d6F41',
  18,
  'STEAK',
  'STEAK'
)

export const GAT = new Token(
  ChainId.CRONOSMAINNET,
  '0x8Ab1C78364eB29aD4aE509fF6b03E3E7bD4f8d10',
  18,
  'GAT',
  'GAT'
)

export const MERY = new Token(
  ChainId.CRONOSMAINNET,
  '0x3b41B27E74Dd366CE27cB389dc7877D4e1516d4d',
  18,
  'MERY',
  'Mistery'
)


// TODO this is only approximate, it's actually based on blocks
export const PROPOSAL_LENGTH_IN_DAYS = 7

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

const UNI_ADDRESS = '0xe243CCab9E66E6cF1215376980811ddf1eb7F689'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.CRONOSMAINNET]: new Token(ChainId.CRONOSMAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap')
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.CRONOSMAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.CRONOSMAINNET]: [WETH[ChainId.CRONOSMAINNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOSMAINNET]: [...WETH_ONLY[ChainId.CRONOSMAINNET], DAI, USDC, USDT, WBTC, WRAPPEDETHER, CRX]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.CRONOSMAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.CRONOSMAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOSMAINNET]: [...WETH_ONLY[ChainId.CRONOSMAINNET], CRX, XCRX, METAVERSEHUB, USDC]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOSMAINNET]: [...WETH_ONLY[ChainId.CRONOSMAINNET], DAI, USDC, USDT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.CRONOSMAINNET]: [
    [
      new Token(ChainId.CRONOSMAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.CRONOSMAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  w: {
    connector: walletconnect,
    name: 'Wallet Connect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect using Wallet Connect.',
    href: null,
    color: '#4A6C9B'
  },
  DEFI_WALLET: {
    connector: defiWalletConnect,
    name: 'DeFi Wallet',
    iconName: 'cro.png',
    description: 'Login using DeFi wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
