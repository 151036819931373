import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '@uniswap/merkle-distributor/build/MerkleDistributor.json'
import { ChainId } from '@cronosdex/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import { GOVERNANCE_ADDRESS, MERKLE_DISTRIBUTOR_ADDRESS, UNI } from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'

import NFT_ABI from '../constants/abis/nft.json'
import CROWD_TICKET_ABI from '../constants/abis/crowd_ticket.json'
import MHUB_TOKEN_ABI from '../constants/abis/MHubTokenABI.json'
import XVAULT_ABI from '../constants/abis/xVault.json'
import RCRX_ABI from '../constants/abis/rCRX.json'

import SPACE_CRXILLION_ABI from '../constants/abis/SpaceCrxillionABI.json'
import ZAPPER_ABI from '../constants/abis/zapper.json'
import ARRIVAL_ADVENTURE_ABI from '../constants/abis/arrivalAdventure.json'
import FIGHT_ADVENTURE_ABI from '../constants/abis/AdventureBattleABI.json'

import MISSION_RECON_ABI from '../constants/abis/missionRecon.json'
import NFT_STAKE_FACTORY_ABI from '../constants/abis/nftStakeFactoryABI.json'

import REWARDS_V2_ABI from '../constants/abis/rewards-V2.json'

import NFT_STAKING_ABI from '../constants/abis/nft_staking_rewards.json'
import IDO_ABI from '../constants/abis/ido.json'
import BOOSTED_STAKING_REWARDS_ABI  from '../constants/abis/boosted_staking_rewards.json'


// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI, false)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23' : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.CRONOSMAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.CRONOSMAINNET:
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(): Contract | null {
  return useContract(GOVERNANCE_ADDRESS, GOVERNANCE_ABI, true)
}

export function useUniContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? UNI[chainId].address : undefined, UNI_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useNFTStakeFactoryContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, NFT_STAKE_FACTORY_ABI, withSignerIfPossible)
}

export function useNFTStakingContract(stakingAddress?: string): Contract | null {
  return useContract(stakingAddress, NFT_STAKING_ABI)
}

export function useBoostedStakingRewardsContract(stakingAddress?: string): Contract | null {
  return useContract(stakingAddress, BOOSTED_STAKING_REWARDS_ABI)
}

export function useIDOContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, IDO_ABI)
}

export function useNFTContract(nftAddress?: string): Contract | null {
  return useContract(nftAddress, NFT_ABI)
}

export function useRewardsV2Contract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, REWARDS_V2_ABI)
}

export function useCrowdTicketContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, CROWD_TICKET_ABI)
}

export function useMHubTokenContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, MHUB_TOKEN_ABI)
}

export function useXVaultContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, XVAULT_ABI)
}

export function useRCRXContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, RCRX_ABI)
}

export function useSpaceCrxillionContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, SPACE_CRXILLION_ABI)
}

export function useZapperContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, ZAPPER_ABI)
}


export function useArrivalAdventureContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, ARRIVAL_ADVENTURE_ABI)
}

export function useFightAdventureContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, FIGHT_ADVENTURE_ABI)
}

export function useMissionReconContract(contractAddress?: string): Contract | null {
  return useContract(contractAddress, MISSION_RECON_ABI)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.CRONOSMAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}
